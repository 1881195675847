.title {
  margin: 0;
  padding-left: -20px;
  position: relative;
  font-size: 22px;
  color: #323232;
  line-height: 25px;
  min-height: 51px;
  font-weight: 600;
  z-index: 2;
  display: flex;

  & > h3 {
    margin: 0 0 0 0;
  }

  & > i {
    font-size: 28px;
    color: var(--primary-color);
  }

  &::after {
    content: "";
    position: absolute;
    left: -30px;
    right: 0;
    bottom: 0;
    height: 3px;
    background: radial-gradient(
      ellipse at left,
      #000 0%,
      rgba(255, 255, 255, 0) 70%
    );
  }
}
