.work-item {
  text-align: center;
  padding: 30px;

  &__slider {
    img {
      width: 100%;
      height: 300px;
    }
  }
  h3 {
    padding: 0;
    display: block;
    font-size: 17px;
    color: #323232;
    font-weight: 500;
    text-transform: none;
    word-break: break-word;
    transition: all .3s ease 0s;
  }

  &__links {
    width: 50%;
    display: flex;
    flex-direction: row;
    z-index: 10;
    position: relative;
    margin: 0 auto;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: radial-gradient(ellipse at center, #ddd 0%, rgba(255, 255, 255, 0) 70%);
    }

    a {
      position: relative;
      display: block;
      width: 100%;
      padding: 10px;
      font-size: 12px;
      color: #323232;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        color: var(--primary-color);
      }

      &:first-child::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: radial-gradient(ellipse at bottom, #ddd 0%, rgba(255, 255, 255, 0) 70%);
      }

      i {
        margin-right: 10px;
      }
    }
  }


}
