.typed-text {
	margin: 0 0 0;
	display: inline-block;
	color: var(--primary-color);
	font-size: 14px;
	height: 20px;
	font-weight: 400;
	z-index: 3;
}

.typed-cursor {
	width: 2px;
	color: #0b0b0b;
	font-size: 14px;
	height: 20px;
	z-index: 3;
}
