@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
@import "~ionicons/scss/ionicons";

// mixins
@import "styles/absctract/_mixins";


:root {
  --primary-color: #78cc6d;
  --secondary-color: black;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*:after, *:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  margin: 8vh 6vw;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1150px;
  transition: all .4s ease 0s;
  -moz-transition: all .4s ease 0s;
  -webkit-transition: all .4s ease 0s;
  -o-transition: all .4s ease 0s;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 74px 80vh ;
  grid-template-areas:
      "hd hd hd hd   hd   hd   hd   hd   hd"
      "sd sd sd main main main main main main";
  z-index: 22;
  border-radius: 4px;
  overflow: hidden;

  @include respond(tab-port) {
    grid-template-areas:
      "hd hd hd hd   hd   hd   hd   hd   hd"
      "sd sd sd sd   sd   sd   sd   sd   sd"
      "main main main main main main main main main";
    grid-auto-rows: unset;
  }

  @include respond(phone) {
    margin: 0;
    border-radius: 0;
  }
}



.page-wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(tab-port) {
    position: static;
  }
}

.page {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 30px 30px 0;
  position: relative;
  background: #fff;
  z-index: 3;
  height: 100%;
  width: 100%;
}

.transition-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: auto 0;
  width: 100%;
  grid-area: main;

  @include respond(tab-port) {
    height: 100vh;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 30px 0;
  position: relative;
  vertical-align: top;
  font-size: 16px;
}

.transition-enter {
  opacity: 0;
  -webkit-transform: translate3d(-70%, 0, 0);
  transform: translate3d(-70%, 0, 0)
}

.transition-enter-active {
  transition-timing-function: linear;
  transition: opacity 1s, transform 1s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  @include respond(tab-port) {
    transition: none;
  }
}

.transition-exit {
  opacity: 1;
  transform: translateX(0%);
}

.transition-exit-active {
  opacity: 0;
  transform: translateX(-200px);
  transition: opacity 300ms, transform 300ms;
  transition-timing-function: ease-out;
  @include respond(tab-port) {
    transition: none;
  }
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.5);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}


@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes customfadeOutLeft {
  from {
    opacity: 1
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0)
  }
}
