.owl-dots {
  position: absolute;
  bottom: -1.2rem;
  left: 0;
}

.owl-dots {
  width: 100%;
  text-align: center;
}

.owl-dots .active.owl-dot {
  background-color: var(--primary-color);
 //  border-color: var(--secondary-color);
}

.owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  margin: 8px 4px;
  border-radius: 10px;
  background-color: #fff;
  padding: 4px;
  border: solid 1px #e9e9e9;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
