.skills {
	text-align: left;
	// margin-top: 30px;
	& > h6 {
		margin-top: 10px;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		& li {
			border: 1px solid #2d2747;
			border-radius: 2px;
			display: inline-block;
			font-size: 13px;
			margin: 0 7px 7px 0;
			padding: 5px;
			cursor: pointer;
			transition: transform 0.3s ease-in;

			&:hover {
				transform: scale(1.1) translateY(-2px);
			}
		}
	}
}
