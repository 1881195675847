@import '../../styles/absctract/mixins';

.profile {
	font-family: Poppins, sans-serif;
	padding: 0;
	grid-area: sd;
	background: #fff;
	text-align: center;
	z-index: 10;
	box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__content {
		padding: 100px 50px 10px 50px;
		position: relative;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.02s ease 1s;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		background-color: white;
	}

	&__banner {
		position: relative;
		height: 35%;
		width: 100%;
		img {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			border-radius: 50%;
			width: 150px;
			opacity: 1 !important;
			object-fit: contain;
			z-index: 22;
			animation: fadeIn reverse;
			cursor: pointer;
		}

		@include respond(tab-port) {
			height: 200px;
		}
	}

	&__photo {
		background-image: url(https://reactjs.org/logo-og.png);
		clip-path: polygon(
			0 0,
			0 87%,
			50% 100%,
			50% 100%,
			50% 100%,
			100% 87%,
			100% 0
		);
		height: 100%;
		width: 100%;
		background-color: rgba(46, 202, 127, 0.1);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-radius: 4px 4px 0 0;
		position: relative;
		overflow: hidden;
	}

	&__title {
		font-size: 27px;
		color: #323232;
		line-height: 32px;
		font-weight: 400;
		z-index: 1;
	}

	&__contact {
		width: 100%;
		height: 70px;
		display: flex;
		flex-direction: row;
		z-index: 10;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 1px;
			background: radial-gradient(
				ellipse at center,
				#ddd 0%,
				rgba(255, 255, 255, 0) 70%
			);
		}

		& > a {
			position: relative;
			display: block;
			width: 100%;
			height: 70px;
			line-height: 70px;
			font-size: 14px;
			color: #323232;
			font-weight: 500;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			border: none;
			cursor: pointer;

			&:hover {
				color: var(--primary-color);
			}

			&:first-child::before {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				width: 1px;
				height: 100%;
				background: radial-gradient(
					ellipse at top,
					#ddd 0%,
					rgba(255, 255, 255, 0) 70%
				);
			}
		}
	}
}
